import React from 'react';
import PropTypes from 'prop-types';
import { TableWrapper as AppDefsTable } from 'cccisd-laravel-appdefs';

export default class ProviderDashboard extends React.Component {
    static propTypes = {
        type: PropTypes.string,
        routes: PropTypes.object,
        table: PropTypes.object,
    };

    static defaultProps = {
        // default props
    };

    render() {
        const { table } = this.props;

        return (
            <div>
                <div style={{ margin: '20px 0 20px 0' }}>
                    <h1>Dashboard</h1>
                </div>
                <AppDefsTable table={table} />
            </div>
        );
    }
}
