import React from 'react';
import PropTypes from 'prop-types';

export default class ManageGroups extends React.Component {
    static propTypes = {
        type: PropTypes.string,
        routes: PropTypes.object,
        table: PropTypes.object,
    };

    static defaultProps = {
        // default props
    };

    render() {
        return (
            <div>
                <div style={{ margin: '0 0 20px 0' }}>
                    <h1>Manage Groups</h1>
                </div>
            </div>
        );
    }
}
